import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import moment from 'moment-timezone';
// const moment = require('moment-timezone');


const $ = require("jquery");
$.Datatable = require("datatables.net");

const DepositTransactions = () => {
  const [user, setUser] = useState();

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  let [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [Req_type, setReq_type] = useState('');
  const [payStatus, setpayStatus] = useState('');
  const [userType, setuserType] = useState('');
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [allAgents, setallAgents] = useState([]);
  const [Referral, setReferral] = useState('No');
  

  const [values, setValues] = useState([
    new DateObject().subtract(4, "days"),
    new DateObject().add(4, "days")
  ])
  //react paginate..
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  const depositTransactions = () => {
    setTotalAmount(0);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(
        baseUrl + `txn/deposit_transactions?page=${pageNumber}&Referral=${Referral}&userType=${userType}&Req_type=${Req_type}&status=${payStatus}&_limit=${limit}&FROM_DATE=${values[0]}&TO_DATE=${values[1]}`,
        { headers }
      )
      .then( async (res) => {
        console.log("-setUser===")
        //console.log(res.data.admin);
        setUser(res.data.datefind);
        setNumberOfPages(res.data.totalPages);
        //$('table').dataTable();
        if (res?.data?.datefind?.length) {
          // settotalAmount(user.reduce((acc, data) => acc + data.amount, 0));
          const total = await res?.data?.datefind.reduce((acc, data) => acc + data.amount, 0);
          console.log("-setUser",total)
          setTotalAmount(total);
        }
      });
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString('default', { year: 'numeric', month: 'numeric', day: 'numeric' });
    return newDate;
  }

  const getAgents = async () => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
  
      const [adminsResponse, agentsResponse] = await Promise.all([
        axios.get(baseUrl + 'admin/all', { headers }),
        axios.get(baseUrl + 'agent/all', { headers })
      ]);
  
      // Combine both arrays and set state
      const allAgents = [...adminsResponse.data, ...agentsResponse.data];
      setallAgents(allAgents);
    } catch (error) {
      console.error("Error fetching agents or admins:", error);
    }
  }
  

  useEffect(()=>{
    getAgents();
  },[])

  useEffect(() => {
    console.log("----load data")
    depositTransactions();
  }, [pageNumber, limit, values, Req_type, payStatus, userType, Referral]);

  if (user === undefined) {
    return null;
  }

  const createDownloadData = () => {
    handleExport();
  };
  const handleExport = () => {
    let table1 = [
      {
        A: "Id",
        B: "UserName",
        C: "PhoneNumber",
        D: "Bonus Amount",
        E: "Bonus By",
        // F:"Bonus by (ID)"
      },
    ];

    user.forEach((row) => {
      const userDetails = row;
      console.log("exldata", userDetails);
      table1.push({
        A: userDetails._id,
        B: userDetails.User_id ? userDetails.User_id.Name : "",
        C: userDetails.User_id ? userDetails.User_id.Phone : "",
        D: userDetails.amount,
        E: userDetails.action_byName ? userDetails.action_byName : "N/A",
        // F:(userDetails.action_by)?userDetails.action_by:'N/A'
      });
    });

    //table1 = [{A:"User Details"}].concat(table1);
    //const finalData = [...table1];
    //console.log(finalData);
    /* convert state to workbook */
    const ws = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "BonusReport.xlsx");
  };
  return (
    <>
      <div className="row ">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body text-light" style={{ backgroundColor: "rgba(0, 27, 11, 0.734)" }}>
              <h4 className="card-title">Deposit Transaction</h4>
              <div className="me-3">
                <label className="form-label">Date Range</label>
                 
                  <DatePicker value={values} onChange={setValues} range  />
                
              </div>
               

              <div className="d-flex align-items-start flex-wrap">

                <div className="me-3">
                  <label className="form-label" htmlFor="pagelimit">Set Limit</label>
                  <select className='form-control bg-dark text-light' id='pagelimit' name='pagelimit' onChange={(e) => setLimit(e.target.value)}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="200">200</option>
                    <option value="1000">1000</option>
                    <option value="5000">5000</option>
                    <option value="15000">15000</option>
                    <option value="25000">25000</option>
                  </select>
                </div>
                <div className="me-3">
                  <label className="form-label" htmlFor="Req_type">Transaction Type</label>
                  <select className='form-control bg-dark text-light' id='Req_type' name='Req_type' onChange={(e) => setReq_type(e.target.value)}>
                    <option value="">All</option>
                    <option value="deposit">Deposit</option>
                    <option value="bonus">Bonus</option>
                    <option value="withdraw">Withdraw</option>
                    <option value="penalty">Penalty</option>
                  </select>
                </div>
                <div>
                  <label className="form-label" htmlFor="payStatus">Status</label>
                  <select className='form-control bg-dark text-light' id='payStatus' name='payStatus' onChange={(e) => setpayStatus(e.target.value)}>
                    <option value="">All</option>
                    <option value="Pending">Pending</option>
                    <option value="pending">Withdraw Pending</option>
                    <option value="PAID">Paid</option>
                    <option value="SUCCESS">Success - Withdrawal</option>
                    <option value="added by admin">Added by admin</option>
                  </select>
                </div>
                <div>
                  <label className="form-label" htmlFor="userType">Status</label>
                  <select className='form-control bg-dark text-light' id='userType' name='userType' onChange={(e) => setuserType(e.target.value)}>
                    <option   value="">All</option>
                    {allAgents && allAgents.map((data, key) => (
                      <option key={data.Phone} value={data._id}>{data.Name} - {data.Phone} ({data.user_type})</option>
                    ))} 
                  </select>
                </div>
                <div>
                  <label className="form-label" htmlFor="Referral">Referral</label>
                  <select className='form-control bg-dark text-light' id='Referral' name='Referral' onChange={(e) => setReferral(e.target.value)}>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                     
                  </select>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table text-light">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th> ID</th>
                      <th> Phone</th>
                      <th> User</th>
                      <th> Amount </th>
                      <th> Req Type </th>
                      <th> Status </th>
                      <th> Date </th>
                      <th> Bonus By</th>
                      <th> Agent/Admin </th>
                    </tr>
                  </thead>

                  <tbody>
                    {user &&
                      user.map((data, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{data._id}</td>
                          <td>
                            <span className="pl-2">
                              {data.User_id ? data.User_id.Phone : ""}
                            </span>
                          </td>
                          <td>
                            {data.User_id && (
                              <Link
                                className="btn btn-sm btn-outline-info"
                                to={`/user/view_user/${data.User_id._id}`}
                              >
                                {data.User_id.Name}
                              </Link>
                            )}
                          </td>

                          <td>{data.amount}</td>
                          <td className="font-weight-bold text-primary">
                            {data.Req_type} ({data.Withdraw_type})
                          </td>
                          <td className="font-weight-bold text-success">
                            {data.status}
                          </td>
                          {/*<td>{data.createdAt} </td>*/}
                           <td>{moment(data.createdAt).tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss')}</td> 
                          <td>{(data.action_by) ? data.action_by.Name : 'N/A'}</td>
                          <td>{(data.action_by) ? data.action_by.user_type : ''}</td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="3" className="text-right font-weight-bold">Total Amount:</td>
                      <td colSpan="5" className="font-weight-bold">{totalAmount}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className="mt-4">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={numberOfPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositTransactions;
